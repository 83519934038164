<template>
    <div class="row mt-2 mb-2">
        <!-- label-->
        <div class="col-10" style="align-self: end;">
            <h6><strong>{{titulo}}</strong></h6>
        </div>

        <div class="row mb-3">
            <!-- Seletor de Campus -->
            <div v-if="campus_variavel" class="col-5 mt-1 mb-1">
                <!-- array_campus_oferta: {{array_campus_oferta}} <br> -->
                campus_selecionado: {{campus_selecionado}} <br>
                <b-form-select placeholder="Escolha um campus" v-model="campus_selecionado" :options="opcoes_campus_select"></b-form-select>
            </div>

            <!-- Botão Editar -->
            <div v-if="campus_variavel" class="col" style="text-align: right;">
                <b-button v-if="campus_selecionado !== null" v-b-modal="'modal-'+code" @click="modalAdicionar('adicionar')" variant="link" style="text-decoration: none"> 
                <b-icon icon="plus-square"></b-icon> Adicionar</b-button>
            </div>
            <div v-else class="col" style="text-align: right;">
                <b-button v-b-modal="'modal-'+code" @click="modalAdicionar('adicionar')" variant="link" style="text-decoration: none"> 
                <b-icon icon="plus-square"></b-icon> Adicionar</b-button>
            </div>
        </div>

        <!-- Texto atual -->
        <div v-if="value" class="col-12">
            <div v-for="(item, id_item) in value" :key="item.id_item">
                <b-table-simple v-if="estaCadastradoNoCampus(item)" small caption-top responsive>
                    <b-thead>
                        <b-tr>
                            <b-th v-if="campus_variavel" colspan="5">{{item.nome}} | {{item.campus}} <!-- | {{id_item}} -->
                                <b-button variant="link" @click="modalEdit(id_item)" style="text-decoration: none"> 
                                    <b-icon icon="pencil-square"></b-icon> Editar
                                </b-button>
                            </b-th>
                            <b-th v-else colspan="5">{{item.nome}} <!-- | {{id_item}} -->
                                <b-button variant="link" @click="modalEdit(id_item)" style="text-decoration: none"> 
                                    <b-icon icon="pencil-square"></b-icon> Editar
                                </b-button>
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="2"><strong>Capacidade:</strong> {{item.capacidade}}</b-td>
                            <b-td colspan="3"><strong>Quantidade:</strong> {{item.quantidade}}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td colspan="5"><strong>Finalidade:</strong> {{item.finalidade}}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple> 
            </div>
        </div>
        <div v-else>
            <p class="sem-texto">
                Não há item cadastrado. Clique em ADICIONAR para inserir.
            </p>
        </div>
        
        <!-- Modal acionado -->
        <b-modal size="lg" :id="'modal-'+code" :title="titulo + ' | ' + id_selecionado" @show="resetModal" @hidden="resetModal">
            {{valor_provisorio}}
            <b-form-group label="Nome:" :label-for="'modal-input-'+code+'nome'">
                <b-form-input :id="'modal-input-'+code+'nome'" v-model="valor_provisorio.nome" rows="3" max-rows="20"></b-form-input>
            </b-form-group>

            <b-form-group label="Quantidade:" :label-for="'modal-input-'+code+'quantidade'">
                <b-form-input :id="'modal-input-'+code+'quantidade'" v-model="valor_provisorio.quantidade" rows="3" max-rows="20"></b-form-input>
            </b-form-group>  

            <b-form-group label="Capacidade:" :label-for="'modal-input-'+code+'capacidade'">
                <b-form-input :id="'modal-input-'+code+'capacidade'" v-model="valor_provisorio.capacidade" rows="3" max-rows="20"></b-form-input>
            </b-form-group>  

            <b-form-group label="Finalidade:" :label-for="'modal-input-'+code+'finalidade'">
                <b-form-input :id="'modal-input-'+code+'finalidade'" v-model="valor_provisorio.finalidade" rows="3" max-rows="20"></b-form-input>
            </b-form-group>     

            <template v-if="modal_editar" #modal-footer>
                <b-button size="sm" variant="danger" @click="delet()">
                    Deletar
                </b-button>
                <b-button size="sm" variant="primary" @click="edit()">
                    Salvar
                </b-button>  
            </template>
            <template v-else #modal-footer>
                <b-button size="sm" variant="primary" @click="Add()">
                    Adicionar
                </b-button>  
            </template>
        </b-modal>

    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    name: 'tabelaInput',
    props: ['titulo', 'code', 'campus_variavel', 'array_campus_oferta', 'value', 'id_curso', 'id_campus', 'forms'],
    data() {
        return {
            valor_provisorio: {
                nome: '',
                finalidade: '',
                quantidade: '',
                capacidade: '',
            },
            modal_editar: false,
            id_selecionado: '',
            campus_nome_selecao: '',
            campus_selecionado: null,
            campus_infos_cadastro: [],
        }
    },
    computed:{
        cadastros_para_campus_selecionado(){
            // Aqui vamos definir um array quandos os dados chegam, pq eles vão se perderm na renderização filtrada do v-for
            if(this.campus_variavel){
                if(this.campus_selecionado !== null){
                    return this.value.filter(x => x.campus == this.campus_selecionado)
                } else {
                    return []
                }
            } else {
                return this.value
            }
            
        },
        opcoes_campus_select(){
            let array_opcoes = []
            array_opcoes.push({ value: null, text: 'Escolha um campus para começar' })
            this.array_campus_oferta.forEach(x => {
                array_opcoes.push({ value: x, text: this.getCampusNome(x)})
            })
            return array_opcoes
        }
    },
    methods: {
        resetModal() {
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio = { nome:'',  finalidade:'', quantidade:'', capacidade:'' }
        },

        estaCadastradoNoCampus(registro){
            if(this.campus_selecionado == registro.campus){
                return registro
            } else {
                return false
            }
        },

        getCampusUninove(){
            // Solicitando os campus
            db.collection("/campus_uninove")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let campus = doc.data()
                    this.campus_infos_cadastro.push(campus)
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        getCampusNome(target_id){
            if(target_id){
                return this.campus_infos_cadastro.find(x => x.id == target_id).nome
            }
        },

        Add(){
            console.log(this.forms)
            let new_array = {
                nome: this.valor_provisorio.nome,
                finalidade: this.valor_provisorio.finalidade,
                quantidade: this.valor_provisorio.quantidade,
                capacidade: this.valor_provisorio.capacidade,
                campus: this.campus_selecionado
            }

            let obj_firebase
            if (!this.value){
                // Se o array não existir, crie um
                obj_firebase = []
            } else {
                obj_firebase = this.value
            }
            obj_firebase.push(new_array)
            console.log(obj_firebase)

            let doc_name
            let path_item
            let colection

            if(this.forms == 'campus_especifico'){
                console.log(`Alterando dados do campus ${this.id_campus}`)
                doc_name = this.id_campus
                path_item = {[`${this.code}`]: obj_firebase}
                colection = '/campus_uninove'
            } else {
                console.log(`Alterando formulário específico de código ${this.id_curso}`)
                doc_name = this.id_curso
                path_item = {[`formulario_especifico.${this.code}`]: obj_firebase}
                colection = '/nadir'
            }
            // console.log(`mudando o ${this.code} e adicionando o valor ${obj_firebase}`)

            db.collection(colection).doc(doc_name)
            .update(path_item)
            .then(() => {
                this.$emit('valorEmitido', this.valor_provisorio)
                this.$bvModal.hide('modal-'+ this.code)
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        modalEdit(item_id){
            this.id_selecionado = item_id
            this.$bvModal.show('modal-' + this.code)
            this.modal_editar = true
            console.log(this.value)
            console.log(this.value[item_id].nome)
            
            this.valor_provisorio.nome = this.value[item_id].nome
            this.valor_provisorio.finalidade = this.value[item_id].finalidade
            this.valor_provisorio.quantidade = this.value[item_id].quantidade
            this.valor_provisorio.capacidade = this.value[item_id].capacidade
            this.valor_provisorio.campus = this.campus_selecionado
            
        },

        edit(){
            this.value[this.id_selecionado] = this.valor_provisorio
            this.value[this.id_selecionado].campus = this.campus_selecionado

            let doc_name
            let path_item
            let colection

            if(this.forms == 'campus_especifico'){
                // console.log(`Alterando dados do campus ${this.id_campus}`)
                doc_name = this.id_campus
                path_item = {[`${this.code}`]: this.value}
                colection = '/campus_uninove'
            } else {
                // console.log(`Alterando formulário específico de código ${this.id_curso}`)
                doc_name = this.id_curso
                path_item = {[`formulario_especifico.${this.code}`]: this.value}
                colection = '/nadir'
            }
            console.log(this.value)
            console.log(doc_name, path_item, colection)

            db.collection(colection).doc(doc_name)
            .update(path_item)
            .then(() => {
                this.$emit('valorEmitido', this.valor_provisorio)
                this.$bvModal.hide('modal-'+ this.code)
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        delet(){
            if(confirm('Tem certeza que desejas excluir o item: ' + this.value[this.id_selecionado].nome + ' ? Todo o preenchimento será perdido.')){
                this.value.splice(this.id_selecionado, 1)

                let doc_name
                let path_item
                let colection

                if(this.forms == 'campus_especifico'){
                    // console.log(`Alterando dados do campus ${this.id_campus}`)
                    doc_name = this.id_campus
                    path_item = {[`${this.code}`]: this.value}
                    colection = '/campus_uninove'
                } else {
                    // console.log(`Alterando formulário específico de código ${this.id_curso}`)
                    doc_name = this.id_curso
                    path_item = {[`formulario_especifico.${this.code}`]: this.value}
                    colection = '/nadir'
                }
                // console.log(this.value)
                // console.log(doc_name, path_item, colection)

                db.collection(colection).doc(doc_name)
                .update(path_item)
                .then(() => {
                    this.$emit('valorEmitido', this.valor_provisorio)
                    this.$bvModal.hide('modal-'+ this.code)
                })
                .catch((error) => {
                    alert('Erro, verifique o console')
                    console.error("Error updating document: ", error);
                });
            }
        },

        modalAdicionar(){
            this.modal_editar = false
            this.$bvModal.show('modal-' + this.code)
        }
    },
    mounted(){
        this.getCampusUninove()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input{
    width: -webkit-fill-available;
}

.sem-texto{
    padding: 10px;
    font-style: italic;
    background: #e8e8e8;
}
</style>
