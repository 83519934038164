<template>
    <div class="container mt-4 mb-5" style="text-align: left">

        <div class="row mb-5">
            <div class="col-9">
                <h3>Campus {{campus_dados.nome}} </h3>
            </div>
            <div class="col" style="text-align: right">
                <b-button variant="outline-primary" style="margin-left: 10px;" to="/">Voltar</b-button>
            </div>
        </div>

        <!-- 1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço). -->
        <div class="row mb-5">
            <itemInput forms="campus_especifico" code="item_1_2"  titulo="1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço)" v-model="campus_dados.item_1_2" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- 5. Instalações e equipamentos -->
        <div class="row mb-2 mt-4">
            <h2>5. Instalações e equipamentos</h2>
            <hr>
        </div>

        <!-- 5.1 Salas de aula -->
        <div class="row mb-4">
            <h6><strong>5.1 Salas de aula</strong></h6>
            <itemInput type="short-text" forms="campus_especifico" code="item_5_1_1" titulo="Nº de salas de aulas:" :id_campus="campus_dados.id" v-model="campus_dados.item_5_1_1" @valorEmitido="log"></itemInput>
            <itemInput type="short-text" forms="campus_especifico" code="item_5_1_2" titulo="Capacidade:" :id_campus="campus_dados.id" v-model="campus_dados.item_5_1_2" @valorEmitido="log"></itemInput>
        </div>

        <!-- 5.4 Outras instalações -->
        <div class="row mb-4">
            <tabelaInput forms="campus_especifico" code="item_5_4" titulo="5.4 Outras instalações" :id_campus="campus_dados.id" v-model="campus_dados.item_5_4" @valorEmitido="log"></tabelaInput>
        </div>

        <!-- 5.5 Recursos de acessibilidade -->
        <div class="row mb-4">
            <tabelaInput forms="campus_especifico" code="item_5_5" titulo="5.5 Recursos de acessibilidade" :id_campus="campus_dados.id" v-model="campus_dados.item_5_5" @valorEmitido="log"></tabelaInput>
        </div>

        <!-- 6.4. Pessoal Técnico Administrativo -->
        <div class="row mt-4 mb-4">
            <tabelaInputRh code="item_6_4" :id_curso="id_curso_atual" :campus_oferta="campus_oferta" titulo="6.4. Pessoal Técnico Administrativo" v-model="campus_dados.item_6_4" @valorEmitido="log"></tabelaInputRh>
        </div>
         
        <!-- {{campus_dados}} -->

    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';
import tabelaInput from '@/views/teste_nadir/components/tabelaInput.vue'
import itemInput from '@/views/teste_nadir/components/itemInput.vue'


// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: { 
        tabelaInput,
        itemInput
    },
    data() {
        return {
            campus_dados: [],     
        }
    },
    computed: {
        // num_unidades_curriculares: function(){
        //     let num = 0
        //     num = this.formulario_especifico.item_4_2_1.length
        //     return num
        // }
    },
    methods: {
        log(value){
            console.log('Item atualizado', value)
            // Após o elemento concluir a inserção de dados no firebase, atualiza os dados
            this.atualizarDados()
        },

        atualizarDados(){
            db.collection("/campus_uninove").doc(this.$route.params.id_campus)
            .get()
            .then((doc) => {
                this.campus_dados = doc.data()
                console.log(this.campus_dados)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        cadastrar(){
            db.collection("/nadir").doc(this.$route.params.id_campus)
            .update({formulario_especifico: this.formulario_especifico})
            .then(() => {
                alert('Alterações salvas com sucesso')
                console.log("Document successfully updated!");
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
            
        },

    },
    mounted() {
        if(this.$route.params.id_campus){
            this.atualizarDados()
        } else {
            alert('Nenhum campus encontrado para o ID especificado')
        }
    }
}
</script>

<style scoped>

</style>